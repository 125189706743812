import { APP_INITIALIZER, inject } from '@angular/core';
import {
	InventoryItemDto,
	InventoryItemEntityName,
	ItemCategoryDto,
	ItemCategoryEntityName,
	ItemDto,
	ItemEntityName,
	ItemMovementDto,
	ItemMovementEntityName
} from '@nest/domain/entities-metadata/dtos';
import { InventoryItemDataAccessService, ItemCategoryDataAccessService, ItemDataAccessService, ItemMovementDataAccessService } from '@nest/domain/items/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const paths = autoPathsFor<ItemDto>();

export const ItemDefaultElements = fieldsToElements(paths(['code', 'name', 'quantity', 'ean', 'manufacturer.name', 'manufacturerCode']));

export function provideItemsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				// let manufacturerDataAccess = inject(ManufacturerDataAccessService);
				let inventoryItemDataAccess = inject(InventoryItemDataAccessService);
				let itemCategoryDataAccess = inject(ItemCategoryDataAccessService);
				// let itemCollectionDataAccess = inject(ItemCollectionDataAccessService);
				let itemMovementDataAccess = inject(ItemMovementDataAccessService);
				let itemDataAccess = inject(ItemDataAccessService);
				// let productCodeTypeDataAccess = inject(ProductCodeTypeDataAccessService);

				return () => {
					// entityMetadata.registerEntityManufacturerDto({
					// 	entityName: ManufacturerEntityName,
					// 	dataAccess: manufacturerDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<ManufacturerDto>()(['id']))
					// });
					entityMetadata.registerEntity({
						entityName: InventoryItemEntityName,
						dataAccess: inventoryItemDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<InventoryItemDto>()(['id']))
					});

					entityMetadata.registerEntity({
						entityName: ItemCategoryEntityName,
						dataAccess: itemCategoryDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<ItemCategoryDto>()(['id']))
					});

					// entityMetadata.registerEntityItemCollectionDto({
					// 	entityName: ItemCollectionEntityName,
					// 	dataAccess: itemCollectionDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<ItemCollectionDto>()(['id']))
					// });

					entityMetadata.registerEntity({
						entityName: ItemMovementEntityName,
						dataAccess: itemMovementDataAccess,
						defaultElements: fieldsToElements(autoPathsFor<ItemMovementDto>()(['id']))
					});

					entityMetadata.registerEntity({
						entityName: ItemEntityName,
						dataAccess: itemDataAccess,
						defaultElements: ItemDefaultElements
					});
					// entityMetadata.registerEntityProductCodeTypeDto({
					// 	entityName: ProductCodeTypeEntityName,
					// 	dataAccess: productCodeTypeDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<ProductCodeTypeDto>()(['id']))
					// });
				};
			}
		}
	];
}
